import React, {
    Suspense,
    lazy,
    memo,
    useRef,
    useState,
    useEffect,
    useCallback,
} from 'react'
import { connect } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'
import delay from 'lodash/delay'

import { useDialog } from '../../hooks'
import { submitRegistration, clearRegistration } from './actions'

import './pizzaexpo.scss'

import logoKens from '../../assets/img/kens-logo.svg'
import logoKensWhite from '../../assets/img/offers/pizza-expo/kens-logo-white.png'
import logoSBR from '../../assets/img/offers/pizza-expo/sbr-logo.svg'
import logoKogi from '../../assets/img/offers/pizza-expo/kogi-logo.svg'
import cocktailImg from '../../assets/img/offers/pizza-expo/cocktail.jpg'

const Modal = lazy(() => import('../../components/modal/Modal'))
const ReCaptcha = lazy(() => import('../../components/re-captcha/ReCaptcha'))

const Pizzaexpo = ({
    sent,
    error,
    sending,
    submitRegistration,
    clearRegistration,
}) => {
    const history = useHistory()
    const recaptchaRef = useRef()
    const errorDialog = useDialog()
    const successDialog = useDialog()
    const [captcha, setCaptcha] = useState(null)
    const [captchaValidation, setCaptchaValidation] = useState(null)
    const {
        formState: { errors },
        register,
        handleSubmit,
        reset,
    } = useForm({ mode: 'onBlur' })

    const handleCaptchaChange = useCallback(
        value => {
            setCaptcha(value)
            setCaptchaValidation(null)
        },
        [setCaptcha, setCaptchaValidation]
    )
    const onSubmit = useCallback(
        data => {
            if (!captcha) {
                return setCaptchaValidation('Please solve the captcha above.')
            }
            submitRegistration({ ...data, captcha })
        },
        [captcha, setCaptchaValidation, submitRegistration]
    )
    const handleModalClose = useCallback(() => {
        setCaptcha('')
        recaptchaRef.current.reset()
        clearRegistration()
        if (!error) {
            reset()
            successDialog.close()
            delay(() => history.push('/'), 500)
        } else {
            errorDialog.close()
        }
    }, [reset, history, error, successDialog, errorDialog, setCaptcha])

    useEffect(() => {
        if (sent) {
            successDialog.open()
        }
    }, [sent])

    useEffect(() => {
        if (error) {
            errorDialog.open()
        }
    }, [error])

    return (
        <>
            <div className="pizza-expo">
                <header>
                    <div className="hero-image"></div>
                    <div className="hero-block">
                        <a className="hero-logo" href="/">
                            <img src={logoKensWhite} alt="Hosted by Ken's" />
                        </a>
                        <div className="hero-title">
                            <h1>
                                Join us for dranks and thangs and a great hang
                                with celebrity chef roy choi
                            </h1>
                        </div>
                    </div>
                </header>

                <div className="content">
                    <div>
                        <h2>
                            Cocktails and fiendishly good bites will be served.
                            <br />
                            <strong>
                                Tuesday, March 28<sup>th</sup> 2023,
                                6:30&nbsp;-&nbsp;8:30&nbsp;p.m.
                            </strong>
                        </h2>
                        <div className="info-block">
                            <div className="content-img">
                                <img src={cocktailImg} alt="" />
                            </div>
                            <div className="content-info">
                                Where:
                                <br />
                                <br />
                                <strong>
                                    Best Friend at the Park MGM Hotel
                                </strong>
                                <br />
                                3770 S Las Vegas Blvd.
                                <br />
                                Las Vegas, NV 89109
                            </div>
                        </div>
                    </div>
                    <div>
                        Let us know if you are coming
                        <form
                            className="registration-form"
                            data-gtm-pizzaexpo-registration-form
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <div className="two-columns">
                                <input
                                    type="text"
                                    placeholder="First Name"
                                    {...register('fname', { required: true })}
                                    className={classNames({
                                        invalid: errors.fname,
                                    })}
                                />
                                <input
                                    type="text"
                                    placeholder="Last Name"
                                    {...register('lname', { required: true })}
                                    className={classNames({
                                        invalid: errors.lname,
                                    })}
                                />
                            </div>
                            <input
                                type="text"
                                placeholder="Operator/Company Name"
                                {...register('company', { required: true })}
                                className={classNames({
                                    invalid: errors.company,
                                })}
                            />
                            <div className="recaptcha">
                                <Suspense
                                    fallback={
                                        <p className="loading">Loading...</p>
                                    }
                                >
                                    <ReCaptcha
                                        recaptchaRef={recaptchaRef}
                                        onChange={handleCaptchaChange}
                                    />
                                </Suspense>
                                {captchaValidation && (
                                    <p style={{ color: '#900' }}>
                                        {captchaValidation}
                                    </p>
                                )}
                            </div>
                            {sending && (
                                <input
                                    disabled
                                    type="submit"
                                    value="Submiting, please wait..."
                                />
                            )}
                            {!sending && (
                                <input type="submit" value="I'll be there!" />
                            )}
                        </form>
                    </div>
                </div>
                <footer>
                    <div>
                        <div className="footer-logos">
                            <a href="/">
                                <img src={logoKens} alt="Ken's" />
                            </a>
                            <a
                                href="https://www.sbrfoodservice.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img src={logoSBR} alt="Sweet Baby Ray's" />
                            </a>
                            <a
                                href="https://www.kogisauce.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src={logoKogi}
                                    alt="Kogi by chef Roy Choi"
                                />
                            </a>
                        </div>
                        <ul className="footer-menu">
                            <li>2023 Ken’s Foods, LLC</li>
                            <li>
                                <a href="/privacy">Privacy Policy</a>
                            </li>
                            <li>
                                <a href="/terms">Terms of Use</a>
                            </li>
                            <li>
                                <a href="/contact">Contact Us</a>
                            </li>
                        </ul>
                    </div>
                </footer>
            </div>

            <Suspense fallback={null}>
                <Modal
                    visible={successDialog.opened}
                    onClose={handleModalClose}
                >
                    <h2>Registration was sent successfully! See you soon.</h2>
                </Modal>
                <Modal visible={errorDialog.opened} onClose={handleModalClose}>
                    <h2 style={{ color: '#900' }}>{error}</h2>
                </Modal>
            </Suspense>
        </>
    )
}

const mapStateToProps = ({ pizzaExpo }) => ({
    sent: pizzaExpo.sent,
    error: pizzaExpo.error,
    sending: pizzaExpo.sending,
})

export default connect(
    mapStateToProps,
    {
        submitRegistration,
        clearRegistration,
    }
)(memo(Pizzaexpo))
